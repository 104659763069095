<script setup lang="ts">
import { computed, watch } from 'vue'

import FloatingContainer from '~/components/shared/FloatingContainer.vue'
import SideNavigation from '~/components/shared/SideNavigation.vue'
import StandNavigation from '~/components/stand/StandNavigation.vue'

import { useRoute } from '#imports'
import { isDesktop } from '~/composables/useMediaQuery'
import { useSideNavigation } from '~/composables/useSideNavigation'

const { openSideNavigation } = useSideNavigation()

const route = useRoute()

const businessEntityUuid = computed(() => route.params.business_entity_uuid as string)

if (!isDesktop.value) {
  openSideNavigation.value = false
}

watch(
  () => route.path,
  () => {
    // モバイルの場合は、遷移するページを選んだらサイドナビを閉じる
    if (!isDesktop.value) {
      openSideNavigation.value = false
    }
  },
)
</script>

<template>
  <div class="grid h-screen grid-rows-[64px,1fr]">
    <StandNavigation />

    <div class="relative flex flex-1 overflow-hidden">
      <transition name="slide">
        <div
          v-if="openSideNavigation"
          class="absolute left-0 top-0 z-3 h-full w-screen md:w-288"
        >
          <SideNavigation
            v-if="businessEntityUuid"
            :business-entity-uuid="businessEntityUuid"
          />
        </div>
      </transition>

      <div
        class="flex w-full flex-col overflow-auto md:pl-[288px] md:transition-all md:duration-[200] md:ease-[ease-out]"
        :class="{ '!pl-0': !openSideNavigation }"
      >
        <slot />
      </div>
      <FloatingContainer />
    </div>
  </div>
</template>

<style scoped lang="scss">
.slide-enter-active,
.slide-leave-active {
  transition: all 0.2s ease-out;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(-288px);
  opacity: 0;
}
</style>
