<script setup lang="ts">
import { useScrollLock } from '@vueuse/core'
import { watch } from 'vue'

import FeedBackModal from '~/components/business_entities/customers/FeedBackModal.vue'
import SideNavigationItem from '~/components/shared/SideNavigationItem.vue'
import StandIconButton from '~/components/stand/StandIconButton.vue'

import { useRoute } from '#imports'
import { useFeature } from '~/composables/useFeature'
import { isDesktop } from '~/composables/useMediaQuery'
import { useSideNavigation } from '~/composables/useSideNavigation'

const route = useRoute()
const { openSideNavigation, toggleSideNavigation } = useSideNavigation()

const props = defineProps<{
  businessEntityUuid: string
}>()

const { readSegment } = await useFeature({ organizationUuid: props.businessEntityUuid })

const navLinks = [
  {
    to: `/business_entities/${props.businessEntityUuid}/customers`,
    text: 'リスト',
  },
]

if (readSegment) {
  navLinks.push({
    to: `/business_entities/${props.businessEntityUuid}/segments`,
    text: 'セグメント',
  })
}

// SP のメニューを開いているときは body のスクロールをロックする
const bodyScrollLocked = useScrollLock(document.body)
watch(
  () => openSideNavigation.value,
  (newValue) => {
    if (newValue && !isDesktop.value) {
      bodyScrollLocked.value = true
    }
    else {
      bodyScrollLocked.value = false
    }
  },
  { immediate: true },
)
</script>

<template>
  <nav class="h-full shrink-0 border-r border-gray-50 bg-white">
    <div class="sticky top-0">
      <div class="flex items-center justify-between p-2">
        <h1 class="text-16 font-bold">
          顧客管理β
        </h1>

        <StandIconButton
          icon="close"
          @click="toggleSideNavigation"
        />
      </div>

      <div class="flex flex-col gap-4 px-2">
        <div>
          <SideNavigationItem
            v-for="link in navLinks"
            :key="link.to"
            :to="link.to"
            :active="route.path === link.to"
          >
            {{ link.text }}
          </SideNavigationItem>
        </div>

        <div>
          <div class="py-0.5 text-13 font-bold leading-[20px] text-label-secondary">
            関連
          </div>

          <button class="block w-full p-1 text-left text-14 leading-[24px] focus:outline-none">
            <FeedBackModal />
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>
